html, body {
    height: 100%;

    @media screen and (max-width: $mobileWide) {
        height: auto;
    }
}
body {
    font-family: $font-family-sans-serif;
}
@mixin group {
    display: block;
    clear: both;

    &:after {
        visibility: hidden;
        display: block;
        font-size: 0;
        content: "";
        clear: both;
        height: 0;
    }
}
.page-wrap {
    min-height: 100%;
    margin-bottom: -$footerHeight;
}
.page-wrap:after {
    content: "";
    display: block;
}
.site-footer, .page-wrap:after {
    height: $footerHeight;
}
.center {
    width: 1000px;
    margin: 0 auto;

    @media screen and (max-width: 1050px) {
        width: 950px;
    }
    @media screen and (max-width: 1000px) {
        width: 100%;
        padding: 0 15px;
        box-sizing: border-box;
    }
    @media screen and (max-width: $androidPortrait) {
        padding: 0;
    }
}
.error {
    font-size: 12px;
    color: red;
    margin-top: 5px;
}
.success {
    font-size: 16px;
    color: green;
    margin-top: 5px;
}
.center-button-holder {
    text-align: center;

    &.enalbe-margin {

        .button {
            margin: 0 10px;

            @media screen and (max-width: 950px) {
                margin: 10px;
            }
        }
    }
}
h1 {
    color: #d72525;
    font-family: $font-family-second;
    text-transform: uppercase;
    font-size: 50px;
    line-height: 44px;
    margin: 30px 0px;

    @media screen and (max-width: $mobileWide) {
        margin: 0 0 30px;
        text-align: center;
    }
}
h2 {
    color: #323232;
    font-family: $font-family-second;
    text-transform: uppercase;
    font-size: 34px;
    line-height: 34px;
    margin: 0;
    padding: 10px 0;

    @media screen and (max-width: $tabletPortrait) {
        font-size: 26px;
        line-height: 34px;
    }
    @media screen and (max-width: $mobileWide) {
        text-align: center;
    }
    @media screen and (max-width: $iphonePortrait) {
        font-size: 22px;
        line-height: 26px;
    }
}
h3 {
    color: #3a3a3a;
    margin: 0;
    font-size: 26px;
    line-height: 42px;
    font-weight: 600;

    @media screen and (max-width: $tabletPortrait) {
        font-size: 18px;
        line-height: 34px;
    }
}
h4 {
    color: #767676;
    font-size: 18px;
    line-height: 24px;

    @media screen and (max-width: $tabletPortrait) {
        font-size: 16px;
        line-height: 22px;
    }
}
p {
    color: #323232;
    font-size: 18px;
    line-height: 32px;
    font-weight: 300;

    @media screen and (max-width: $tabletPortrait) {
        font-size: 16px;
        line-height: 30px;
    }
    @media screen and (max-width: $mobileWide) {
        font-size: 18px;
        line-height: 32px;
        text-align: center;
    }
    @media screen and (max-width: $androidPortrait) {
        font-size: 16px;
        line-height: 30px;
    }
}
input, select, textarea {
    padding: 20px 15px;
    border: 2px solid rgba(159, 163, 167, 0.5);
    margin-top: 30px;
    display: block;
    border-radius: 4px;
    width: 100%;
    box-sizing: border-box;
    outline: none;

    &:first-child {
        margin-top: 0;
    }
}
textarea {
    height: 120px;
}
.sup {
  vertical-align: super;
   font-size: smaller;
}
.button {
    padding: 15px 40px;
    display: inline-block;
    line-height: 23px;
    transition: all 0.4s;
    border: 2px solid transparent;
    box-sizing: border-box;
    text-decoration: none;
    border-radius: 30px;
    text-align: center;
    font-weight: 500;
    font-size: 20px;
    cursor: pointer;
    min-width: 300px;

    @media screen and (max-width: 1150px) {
        min-width: 250px;
    }
    @media screen and (max-width: 950px) {
        width: 100%;
        max-width: 300px;
        min-width: 0;

        &.bigger-button {
            width: auto;
            max-width: none;
        }
    }
    @media screen and (max-width: $tabletPortrait) {
        max-width: 250px;
        font-size: 16px;
        padding: 12px 24px;
    }
}
.main-button {
    color: #d72525;
    background: #fff;
    border-color: #d72525;

    &:hover, &.active, &.mixitup-control-active {
        color: #fff;
        background: #d72525;
    }
}
.white-button {
    color: #fff;
    background: transparent;
    border-color: #fff;

    &:hover, &.active, &.mixitup-control-active {
        color: #3a3a3a;
        background: #fff;
    }
}
.transparent-button {
    color: #d72525;
    background: transparent;
    border-color: #d72525;

    &:hover, &.active, &.mixitup-control-active {
        color: #fff;
        background: #d72525;
    }
}
.red-link {
    color: #d72525;
    text-decoration: underline;
}

// top header style
.top-header {
    padding-top: 20px;
    width: 100%;
    height: 100%;
    max-height: 1000px;

    .center {
        overflow: hidden;

        @media screen and (max-width: $androidPortrait) {
            padding: 20px 15px 0;
        }
    }
    .logo {

        @media screen and (max-width: $mobileWide) {
            display: none;
        }
    }
}
.logo {

    svg {
        width: 200px;
        height: auto;
        // ie fix
        max-height: 80px;
    }
}
.de {

    .people-holder {

        .people-holder-text {

            @media screen and (max-height: 700px) and (min-width: 1000px) {
                left: 102px;
            }
        }
    }
    .action-text {
        font-size: 28px;
        line-height: 32px;
    }
    .counter-holder {

        .single-counter {

            p {

                @media screen and (min-width: 800px) {
                    min-height: 130px;
                }

                &:last-child {
                    min-height: 0;
                }
            }
        }
    }
    .single-person-holder {

        &.second-guy {

            p {

                @media screen and (max-height: 800px), screen and (max-width: 1000px) {
                    bottom: 50px;
                }
            }
        }
        &.third-guy {

            p {

                @media screen and (max-height: 800px), screen and (max-width: 1000px) {
                    width: 105px;
                }
            }
        }
        p {

            @media screen and (max-height: 800px), screen and (max-width: 1000px) {
                font-size: 13px;
                line-height: 20px;
            }
        }
    }
}
.single-person-holder {
    position: absolute;
    width: 310px;
    height: 300px;
    border-radius: 150px;
    transition: all 1.6s;
    z-index: 2;

    @media screen and (max-height: 800px), screen and (max-width: 1000px) {
        width: 210px;
        height: 200px;
    }
    @media screen and (max-width: $mobileWide) {
        width: 250px;
        height: 240px;
    }

    &.first-guy {
        top: -80px;
        left: 150px;
        transform: translateY(-700px);
        -webkit-transform: translateY(-700px);

        @media screen and (max-height: 800px), screen and (max-width: 1000px) {
            left: 90px;
            top: -50px;
        }
        @media screen and (max-width: $mobileWide) {
            left: 150px;
        }
        @media screen and (max-width: 430px) {
            left: 100px;
        }
        @media screen and (max-width: $peopleBeforeIphone) {
            left: 50px;
            width: 210px;
            height: 200px;
        }

        .circle {
            background: #444444;
        }
        img {
            bottom: 2px;
            right: 20px;
            border-bottom-right-radius: 119px;

            @media screen and (max-height: 800px), screen and (max-width: 1000px) {
                bottom: 1px;
                right: 0px;
                border-bottom-right-radius: 99px;
            }
            @media screen and (max-width: $mobileWide) {
                bottom: 2px;
                right: 15px;
                border-bottom-right-radius: 95px;
            }
            @media screen and (max-width: $peopleBeforeIphone) {
                right: 7px;
            }
        }
        p {
            width: 122px;
            bottom: 65px;
            left: 27px;

            @media screen and (max-height: 800px), screen and (max-width: 1000px) {
                width: 105px;
                bottom: 28px;
                left: 18px;
            }
            @media screen and (max-width: $mobileWide) {
                bottom: 48px;
                left: 28px;
            }
            @media screen and (max-width: $peopleBeforeIphone) {
                bottom: 28px;
                left: 20px;
            }
        }
    }
    &.second-guy {
        top: auto;
        bottom: 10px;
        left: -110px;
        width: 260px;
        height: 250px;
        transform: translateX(-480px);
        -webkit-transform: translateX(-480px);

        @media screen and (max-height: 800px), screen and (max-width: 1000px) {
            width: 190px;
            height: 180px;
            left: -90px;
        }
        @media screen and (max-width: $mobileWide) {
            left: 40px;
        }
        @media screen and (max-width: 510px) {
            bottom: 130px;
        }
        @media screen and (max-width: $androidPortrait) {
            bottom: 135px;
        }

        .circle {
            background: #d72525;
        }
        img {
            bottom: 0;
            left: -30px;
        }
        p {
            width: 100px;
            left: 115px;

            @media screen and (max-height: 800px), screen and (max-width: 1000px) {
                width: 90px;
                left: 75px;
                bottom: 40px;
            }
        }
    }
    &.third-guy {
        width: 260px;
        height: 250px;
        bottom: 50px;
        right: -80px;
        top: auto;
        left: auto;
        transform: translateX(600px);
        -webkit-transform: translateX(600px);

        @media screen and (max-height: 800px), screen and (max-width: 1000px) {
            width: 190px;
            height: 180px;
            right: -90px;
        }
        @media screen and (max-width: $mobileWide) {
            right: 20px;
        }
        @media screen and (max-width: 650px) {
            right: 100px;
        }
        @media screen and (max-width: 550px) {
            right: 160px;
            bottom: 30px;
        }
        @media screen and (max-width: 510px) {
            right: 230px;
            bottom: -30px;
        }
        @media screen and (max-width: 430px) {
            right: 280px;
        }
        @media screen and (max-width: $peopleBeforeIphone) {
            left: 115px;
            bottom: -60px;
            right: auto;
        }
        @media screen and (max-width: 370px) {
            left: 105px;
        }
        @media screen and (max-width: 355px) {
            left: 95px;
        }
        @media screen and (max-width: 345px) {
            left: 85px;
        }
        @media screen and (max-width: $iphonePortrait) {
            left: 70px;
        }

        .circle {
            background: #aeaeae;
        }
        img {
            bottom: -10px;
            right: -25px;
            transform: rotateY(180deg);
            transform-origin: 50% 50%;
        }
        p {
            bottom: 75px;
            left: 25px;
            width: 128px;

            @media screen and (max-height: 800px), screen and (max-width: 1000px) {
                width: 95px;
                left: 20px;
                bottom: 45px;
            }
        }
    }
    &.visible {
        transform: translateY(0) translateX(0);
        -webkit-transform: translateY(0) translateX(0);
    }

    .circle {
        width: 100%;
        height: 100%;
        border-radius: 150px;
        transition: all 0.4s;
        backface-visibility: hidden;
        filter: blur(0);
        box-shadow: 0px 4px 30px 2px rgba(0, 0, 0, 0.2);
    }
    img {
        width: auto;
        height: 450px;
        position: absolute;
        transition: all 0.4s;
        transform-origin: 100% 100%;

        @media screen and (max-height: 800px), screen and (max-width: 1000px) {
            height: 280px;
        }
        @media screen and (max-width: $peopleBeforeIphone) {
            height: 230px;
        }
    }
    p {
        font-size: 16px;
        font-weight: 300;
        line-height: 26px;
        color: #fff;
        position: absolute;
        bottom: 70px;
        left: 30px;
        text-align: center;

        @media screen and (max-height: 800px), screen and (max-width: 1000px) {
            font-size: 16px;
            line-height: 22px
        }

        span {
            display: block;
            width: 0;
            overflow: hidden; /* Ensures the content is not revealed until the animation */
            white-space: nowrap; /* Keeps the content on a single line */
            margin: 0 auto; /* Gives that scrolling effect as the typing happens */

            &.first-el {
                animation:
                    typing 1s steps(40, end) 1.5s,
                    blink-caret .75s step-end infinite,
                    remove-maker 0.1s infinite 2.5s;
            }
            &.second-el {
                animation:
                    typing 1s steps(40, end) 2.5s,
                    blink-caret .75s step-end infinite,
                    remove-maker 0.1s infinite 3.5s;
            }
            &.third-el {
                animation:
                    typing 1s steps(40, end) 3.5s,
                    blink-caret .75s step-end infinite,
                    remove-maker 0.1s infinite 4.5s;
            }
        }
    }
}
.action-text {
    color: #fff;
    font-family: $font-family-second;
    font-size: 32px;
    box-shadow: 0px 4px 40px 5px rgba(0, 0, 0, 0.25);
    line-height: 36px;
    width: 120px;
    background: #fff;
    height: 120px;
    border: 4px solid transparent;
    position: absolute;
    border-radius: 150px;
    text-align: center;
    text-transform: uppercase;
    transition: all 0.4s;
    display: table;
    text-decoration: none;

    @media screen and (max-height: 800px), screen and (max-width: 1000px) {
        width: 110px;
        height: 110px;
    }

    &.black {
        color: #444444;
        border-color: #444444;
        left: -20px;
        top: -30px;

        &:hover {
            color: #fff;
            background: #444444;
            border-color: #fff;
        }
    }
    &.red {
        bottom: -40px;
        left: -35px;
        z-index: 2;
        color: #d72525;
        border-color: #d72525;

        &:hover {
            background: #d72525;
            color: #fff;
            border-color: #fff;
        }
    }
    &.gray {
        bottom: -60px;
        right: -25px;
        z-index: 2;
        color: #aeaeae;
        border-color: #aeaeae;

        &:hover {
            background: #aeaeae;
            color: #fff;
            border-color: #fff;
        }
    }

    span {
        display: table-cell;
        vertical-align: middle;
        width: 100%;
        height: 100%;
        padding: 0 5px;
    }
}
.people-holder {
    position: relative;
    width: 600px;
    height: 600px;
    border: 0;
    // border: 100px solid transparent;
    background: transparent;
    box-sizing: border-box;
    margin: 150px auto 100px;

    @media screen and (max-height: 800px), screen and (max-width: 1000px) {
        margin: 50px auto;
        width: 400px;
        height: 400px;
    }
    @media screen and (max-width: $mobileWide) {
        width: 600px;
        height: 600px;
        margin-top: 150px;
    }
    @media screen and (max-width: 510px) {
        margin-bottom: 120px;
    }

    &:before,
    &:after {
        border-radius: 500px;
        box-sizing: border-box;
        content: '';
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
    }
    &:before {
        border: 100px solid transparent;

        @media screen and (max-height: 800px), screen and (max-width: 1000px) {
            border-width: 70px;
        }
        @media screen and (max-width: $mobileWide) {
            border-width: 100px;
        }
    }
    &.visible:before {
        border-top-color: #f1f1f1; // Show borders
        border-right-color: #f1f1f1;
        border-bottom-color: #f1f1f1;
        transition:
          border-top-color 0.6s linear, // Stagger border appearances
          border-right-color 0.6s linear 0.30s,
          border-bottom-color 0.6s linear 0.60s;
    }
    &:after {
        border: 0 solid transparent; // Makes border thinner at the edges? I forgot what I was doing
    }
    &.visible:after {
        border-top: 100px solid #f1f1f1; // Shows border
        border-left-width: 100px; // Solid edges, invisible borders
        border-right-width: 100px; // Solid edges, invisible borders
        transform: rotate(270deg); // Rotate around circle
        transition:
          transform 1.2s linear 0s,
          border-left-width 0s linear 0.35s; // Solid edge post-rotation

        @media screen and (max-height: 800px), screen and (max-width: 1000px) {
            border-width: 70px;
        }
        @media screen and (max-width: $mobileWide) {
            border-width: 100px;
        }
    }

    .people-holder-text {
        position: absolute;
        top: 235px;
        left: 140px;

        @media screen and (max-height: 800px), screen and (max-width: 1000px) {
            top: 165px;
            left: 105px;
        }
        @media screen and (max-width: $mobileWide) {
            top: 245px;
            left: 170px;
        }
        @media screen and (max-width: 650px) {
            top: 215px;
        }
        @media screen and (max-width: 550px) {
            left: 155px;
        }
        @media screen and (max-width: 510px) {
            left: 220px;
            top: 225px;
        }
        @media screen and (max-width: 400px) {
            left: 215px;
        }
        @media screen and (max-width: $peopleBeforeIphone) {
            left: 165px;
            top: 180px;
        }
        @media screen and (max-width: 365px) {
            left: 155px;
        }
        @media screen and (max-width: 355px) {
            top: 180px;
        }
        @media screen and (max-width: $iphonePortrait) {
            left: 145px;
            top: 175px;
        }

        p {
            font-family: $font-family-second;
            text-align: center;
            margin: 0;
            font-size: 52px;
            line-height: 62px;

            @media screen and (max-height: 800px), screen and (max-width: 1000px) {
                font-size: 32px;
                line-height: 40px;
            }
            @media screen and (max-width: $mobileWide) {
                font-size: 44px;
                line-height: 52px;
            }
            @media screen and (max-width: 510px) {
                font-size: 34px;
                line-height: 42px;
            }
            @media screen and (max-width: 430px) {
                font-size: 28px;
                line-height: 34px;
            }
            @media screen and (max-width: $peopleBeforeIphone) {
                font-size: 30px;
                line-height: 36px;
            }
            @media screen and (max-width: 350px) {
                font-size: 28px;
                line-height: 34px;
            }

            .red {
                color: #d72525;
            }
        }
    }
}
.change-language-holder {
    position: absolute;
    top: 0;
    right: 0;

    @media screen and (max-width: 1200px) {
        right: 15px;
    }
    @media screen and (max-width: $mobileWide) {
        z-index: 20;
        right: auto;
        left: 140px;
        top: 24px;
        position: fixed;
    }

    a, span {
        display: inline-block;
        vertical-align: top;
    }
    span {
        margin: 0 5px;

        @media screen and (max-width: $mobileWide) {
            color: #fff;
        }
    }
    a {
        text-transform: uppercase;
        text-decoration: none;
        font-weight: 600;
        color: #444444;
        transition: all 0.4s;

        @media screen and (max-width: $mobileWide) {
            color: #fff;
        }

        &:hover {
            color: #d72525;
        }
        &.active {
            color: #d72525;
        }
    }
}
.home {

    .center {
        width: 1170px;
        position: relative;

        @media screen and (max-width: 1200px) {
            width: 100%;
            padding: 0 15px;
            box-sizing: border-box;
        }
        @media screen and (max-width: $androidPortrait) {
            padding: 0 0 20px;
        }
    }
    h2 {
        font-size: 55px;
        line-height: 62px;

        @media screen and (max-width: $androidPortrait) {
            font-size: 40px;
            line-height: 46px;
        }
    }
    .header-height {
        display: block;
    }
}
header {
    position: relative;
    height: auto;
    width: auto;

    .header-height {
        height: 80px;

        @media screen and (max-width: $tabletWide) {
            height: 68px;
        }
        @media screen and (max-width: $tabletPortrait) {
            height: 59px;
        }
        @media screen and (max-width: $mobileWide) {
            display: none;
        }
    }
}
.show-navigation {
    display: none;

    @media screen and (max-width: $mobileWide) {
        display: block;
        position: absolute;
        top: 20px;
        right: 25px;
        z-index: 11;
        width: 35px;
        height: 40px;
        -webkit-transform: rotate(0deg);
        -moz-transform: rotate(0deg);
        -o-transform: rotate(0deg);
        transform: rotate(0deg);
        -webkit-transition: .5s ease-in-out;
        -moz-transition: .5s ease-in-out;
        -o-transition: .5s ease-in-out;
        transition: .5s ease-in-out;
        cursor: pointer;
    }

    &.spinned {

        span:nth-child(1) {
            top: 12px;
            -webkit-transform: rotate(135deg);
            -moz-transform: rotate(135deg);
            -o-transform: rotate(135deg);
            transform: rotate(135deg);
        }

        span:nth-child(2) {
            opacity: 0;
            left: -60px;
        }

        span:nth-child(3) {
            top: 12px;
            -webkit-transform: rotate(-135deg);
            -moz-transform: rotate(-135deg);
            -o-transform: rotate(-135deg);
            transform: rotate(-135deg);
        }
    }

    span {
        display: block;
        position: absolute;
        height: 4px;
        width: 100%;
        background: #fff;
        border-radius: 9px;
        opacity: 1;
        left: 0;
        -webkit-transform: rotate(0deg);
        -moz-transform: rotate(0deg);
        -o-transform: rotate(0deg);
        transform: rotate(0deg);
        -webkit-transition: .25s ease-in-out;
        -moz-transition: .25s ease-in-out;
        -o-transition: .25s ease-in-out;
        transition: .25s ease-in-out;

        &:nth-child(1) {
            top: 0px;
        }

        &:nth-child(2) {
            top: 12px;
        }

        &:nth-child(3) {
            top: 24px;
        }
    }
}
.mobile-logo {
    display: none;

    @media screen and (max-width: $mobileWide) {
        display: inline-block;
        position: fixed;
        top: 15px;
        left: 10px;
        z-index: 12;

        svg {
            width: 90px;
            max-height: 35px;
        }
    }
}
.main-menu {
    background: #323232;
    text-align: center;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 10;
    width: 100%;

    @media screen and (max-width: $mobileWide) {

        &.translate-opacity {
            opacity: 1;
            visibility: visible;
        }
    }
    @media screen and (max-width: $mobileWide) {
        position: fixed;
        width: 100%;
    }

    &.scrolled {
        position: fixed;

        ul li.menu-logo {
            margin-left: 0;
        }
    }
    &:after {
        display: none;
        content: '';
        width: 100%;
        height: 68px;
        background: #323232;
        position: fixed;
        top: 0;
        left: 0;

        @media screen and (max-width: $mobileWide) {
            display: block;
        }
    }

    ul {
        @include group;
        list-style: none;
        padding: 0;
        margin: 0;
        overflow: hidden;
        display: inline-flex;
        float: none;

        @media screen and (max-width: $mobileWide) {
            float: left;
            display: block;
            overflow: visible;
            min-height: 68px;
            position: absolute;
            top: 68px;
            left: 100%;
            z-index: 10;
            transition: all 0.4s;
            background: #323232;
            width: 250px;

            &.visible {
                transform: translateX(-250px);
                -webkit-transform: translateX(-250px);
                -ms-transform: translateX(-250px);
                -moz-transform: translateX(-250px);
            }
        }

        li {
            display: inline-block;
            vertical-align: middle;

            @media screen and (max-width: $mobileWide) {
                display: block;
            }

            &.menu-logo {
                transition: all 0.4s;
                margin-left: -160px;

                @media screen and (max-width: $tabletWide) {
                    margin-left: -120px;
                }
                @media screen and (max-width: $mobileWide) {
                    margin-left: 0;
                    display: none;
                }

                a {
                    padding: 14px 25px 15px;

                    @media screen and (max-width: $tabletPortrait) {
                        padding: 10px 15px 10px;
                    }

                    &:hover {
                        background: #323232;
                    }
                }
                svg {
                    width: 120px;
                    max-height: 50px;

                    @media screen and (max-width: $tabletWide) {
                        width: 90px;
                        max-height: 35px;
                    }
                }
                .white-background {
                    fill: #fff;
                }
            }

            a {
                font-size: 18px;
                font-weight: 300;
                padding: 30px 25px;
                color: #fff;
                text-transform: uppercase;
                text-decoration: none;
                transition: all 0.4s;
                display: block;

                @media screen and (max-width: $tabletWide) {
                    font-size: 16px;
                    padding: 25px;
                }
                @media screen and (max-width: $tabletPortrait) {
                    padding: 20px 10px;
                }
                @media screen and (max-width: $mobileWide) {
                    padding: 25px;
                }

                &:hover {
                    background: #1c1c1c;
                    color: #fff;
                }
            }
        }
    }
}
.single-section {
    padding: 40px 0;
    position: relative;

    &.right-sided {
        text-align: right;
    }
    &.potential-section {
        padding: 60px 0 120px;
        box-sizing: border-box;

        @media screen and (max-width: $tabletWide) {
            padding: 60px 20px 120px;
        }
        @media screen and (max-width: $tabletPortrait) {
            padding: 40px 20px;
        }

        .center {
            position: relative;
        }
    }
    &.mindset-section {

        .center {
            position: relative;
            overflow: hidden;
        }
        .right-side-person {
            top: -105px;
            height: 125%;

            &.second-right-guy {
                top: 0;
                height: 110%;
            }

            @media screen and (max-width: $mobileWide) {
                height: 450px;
                bottom: 0;
                top: auto;

                &.second-right-guy {
                    display: none;
                }
            }
            @media screen and (max-width: $androidPortrait) {
                display: none;
            }
        }
    }
    &.testimonials {
        background: url('../img/testimonials-section.jpg') no-repeat center top / cover;

        .quotes-slider {
            opacity: 1;
            visibility: visible;
        }
    }

    @media screen and (max-width: $tabletWide) {
        padding: 60px 20px;
    }
    @media screen and (max-width: $tabletPortrait) {
        padding: 40px 20px;
    }

    section {
        display: inline-block;
        width: 680px;
        padding: 50px 0;
        text-align: left;

        &.right-side-section{

            @media screen and (max-width: $mobileWide) {
                padding-left: 100px;
            }
            @media screen and (max-width: $androidPortrait) {
                padding-left: 0;
            }
        }

        @media screen and (max-width: $tabletWide) {
            width: 620px;
        }
        @media screen and (max-width: $tabletPortrait) {
            padding: 0 0 40px;
            width: 450px;
        }
        @media screen and (max-width: $mobileWide) {
            width: 100%;
            max-width: 550px;
            box-sizing: border-box;
        }
    }
    .left-side-person {
        position: absolute;
        left: 0;
        top: 0;
        width: auto;
        height: 100%;

        &.visible {

            @media screen and (max-width: $mobileWide) {
                transform: rotate(15deg);
                -webkit-transform: rotate(15deg);
                -moz-transform: rotate(15deg);
                -ms-transform: rotate(15deg);
                left: -75px;
                height: 520px;
            }
            @media screen and (max-width: $androidPortrait) {
                display: none;
            }
        }
    }
    .right-side-person {
        position: absolute;
        right: 0;
        top: 0;
        width: auto;
        height: 100%;

        @media screen and (max-width: 1000px) {
            height: 50%;
        }
        @media screen and (max-width: $mobileWide) {
            display: none;
        }
    }
    .two-button-holder {

        @media screen and (max-width: $mobileWide) {
            padding: 0 0 20px;
        }
    }
}
.button-holder {
    @include group;

    @media screen and (max-width: 950px) {
        text-align: center;
    }

    .button {
        margin: 0 15px;

        @media screen and (max-width: 950px) {
            margin: 10px 15px;
        }
        @media screen and (max-width: $tabletPortrait) {
            margin: 5px 0;
        }

        &:first-child {
            margin-left: 0;
        }
        &:last-child {
            margin-right: 0;
        }
    }
}
.inner-section {
    padding-bottom: 50px;
    @include group;

    @media screen and (max-width: $mobileWide) {
        padding-bottom: 20px;
    }

    &:last-child {
        padding-bottom: 0;
    }
    &.button-and-header-section {
        text-align: center;
        padding-top: 40px;

        h2 {
            padding-bottom: 40px;
        }
        .two-button-holder {
            margin: 0 auto;
        }
    }
    &.contact-us {

        @media screen and (max-width: $tabletPortrait) {
            padding-bottom: 20px;
        }

        h2 {

            @media screen and (max-width: $tabletPortrait) {
                text-align: center;
            }
        }
    }
    &.padding-section {
        padding: 50px 0;
    }
    &.single-post-section {
        padding: 40px 0;

        ul, ol {
            margin-bottom: 30px;
        }
    }
    &.top-members-section {

        @media screen and (max-width: 1150px) {
            padding-bottom: 0;
        }
    }
    .brandon-hall-image {
        display: block;
        margin: 20px auto 0;
    }
    h3 {
        color: #323232;
        font-family: $font-family-second;
        text-transform: uppercase;
        font-size: 34px;
        line-height: 34px;
        margin: 0;
        padding: 0;
    }
    ul, ol {
        padding-left: 90px;
        margin-bottom: 40px;

        &:last-child {
            margin-bottom: 0;
        }

        @media screen and (max-width: $androidPortrait) {
            padding-left: 20px;
        }

        li {
            color: #323232;
            font-weight: 300;
            font-size: 18px;
            line-height: 32px;
            padding: 5px 0;

            @media screen and (max-width: $androidPortrait) {
                font-size: 16px;
                line-height: 22px;
            }

            a {
                color: #323232;
                font-weight: 300;
                font-size: 18px;
                text-decoration: none;

                &:hover {
                    text-decoration: underline;
                }
            }
            .red-link {
                font-weight: 500;
            }
        }
    }
    p.paragraph-button-holder {

        &.centered {
            text-align: center;
        }

        .button {

            &.three-styled-buttons {
                margin: 20px 10px;
            }
            &.many-styled-buttons {
                margin: 20px;
            }
        }
    }
    .inner-section-left-side, .inner-section-right-side {
        float: left;
        width: 50%;
        box-sizing: border-box;

        @media screen and (max-width: $tabletPortrait) {
            float: none;
            width: 100%;
            text-align: center;
        }
    }
    .inner-section-left-side {
        padding-right: 150px;

        @media screen and (max-width: 1200px) {
            padding-right: 200px;
        }
        @media screen and (max-width: 1000px) {
            padding-right: 20px;
        }
        @media screen and (max-width: $tabletPortrait) {
            padding: 0 20px;
        }
    }
    .inner-section-right-side {
        padding-left: 50px;

        @media screen and (max-width: $tabletPortrait) {
            padding: 0 20px;
        }

        form {
            width: 400px;
            margin: 0 auto;

            @media screen and (max-width: 1000px) {
                width: 100%;
                max-width: 400px;
            }
        }
        .button {
            width: auto;
        }
        .center-button-holder {
            margin-top: 30px;
        }
    }
}
.two-button-holder {
    @include group;
    max-width: 650px;
    padding: 20px 0;
    margin: 0;

    &.two-button-holder-center {
        margin: 0 auto;
    }

    @media screen and (max-width: $tabletPortrait) {
        padding: 0;
    }
    @media screen and (max-width: $mobileWide) {
        padding: 10px 0;
        text-align: center;
    }

    .button {
        box-sizing: border-box;

        @media screen and (max-width: $tabletPortrait) {
            margin: 10px 0;
        }
        @media screen and (max-width: $mobileWide) {
            float: none;
            display: inline-block;
        }

        &:first-child {
            float: left;

            @media screen and (max-width: $tabletPortrait) {
                float: none;
                margin-left: 0;
            }
            @media screen and (max-width: $mobileWide) {
                margin-top: 0;
            }
        }
        &:last-child {
            float: right;

            @media screen and (max-width: $tabletPortrait) {
                float: none;
                margin-right: 0;
            }
            @media screen and (max-width: $mobileWide) {
                margin-bottom: 0;
            }
        }
    }
}
.top-header-holder {
    display: table;
    width: 100%;
    height: 100%;

    &.phoned {
        display: block;
        width: auto;
        height: auto;

        .aligned-header {
            display: block;
            width: auto;
            height: auto;
        }
    }

    @media screen and (max-width: 1000px) {
        display: block;
        width: auto;
        height: auto;
    }

    .aligned-header {
        display: table-cell;
        width: 100%;
        height: 100%;
        vertical-align: middle;

        @media screen and (max-width: 1000px) {
            display: block;
            width: auto;
            height: auto;
        }
    }
}
footer {

    .main-footer {
        background: #363636;
        padding-bottom: 20px;
        position: relative;
        @include group;
    }
    .footer-side {
        width: 33%;
        float: left;
        box-sizing: border-box;
        padding: 20px 15px;

        @media screen and (max-width: $tabletPortrait) {
            padding: 20px 40px;
        }
        @media screen and (max-width: $mobileWide) {
            display: block;
            width: 100%;
            text-align: center;
            padding: 40px 20px 20px;
        }

        &:first-child {
            padding-left: 0;

            @media screen and (max-width: $mobileWide) {
                padding-left: 20px;
            }
        }
        &:last-child {
            padding-right: 0;

            @media screen and (max-width: $mobileWide) {
                padding-right: 20px;
            }
        }

        ul {
            padding: 0;
            margin: 0;
            list-style: none;

            li {
                margin: 8px 0;

                &:first-child {
                    margin-top: 0;
                }
                &:last-child {
                    margin-bottom: 0;
                }

                a {
                    text-decoration: none;
                    font-weight: 200;
                    display: inline-block;

                    &:hover {
                        font-weight: 400;
                    }
                }
                hr {
                    border: 0;
                    border-bottom: 1px dotted rgba(255, 255, 255, 0.7);
                    width: 260px;
                    margin: 18px 0;

                    @media screen and (max-width: $tabletWide) {
                        width: 120px;
                    }
                    @media screen and (max-width: $mobileWide) {
                        width: 100%;
                        max-width: 280px;
                        margin: 10px auto;
                    }
                }
            }
        }
        h4 {
            color: #fff;
            font-weight: 600;
            text-transform: uppercase;
            font-size: 20px;

            @media screen and (max-width: $tabletWide) {
                font-size: 18px;
            }
            @media screen and (max-width: $mobileWide) {
                margin-top: 0;
            }
        }
        p {
            color: #fff;
            font-size: 16px;
            line-height: 26px;

            a {
                text-decoration: none;

                &:hover {
                    text-decoration: underline;
                }
            }
        }
        a {
            color: #fff;
        }
        img {
            width: 150px;
            height: auto;
            margin: 0 auto;
            display: block;

            @media screen and (max-width: $tabletWide) {
                width: 120px;
            }
        }
    }
}
.rights-footer {
    padding: 20px 0;

    p {
        font-size: 16px;
        line-height: 19px;
        text-align: center;
        font-weight: 300;
        margin: 0;
        padding: 0 20px;

        @media screen and (max-width: $androidPortrait) {
            font-size: 14px;
        }
        @media screen and (max-width: 380px) {
            padding: 0 10px;
            font-size: 12px;
        }
    }
}
.trusted-by {
    background: #3a3a3a;
    padding: 20px 0;
    position: relative;

    .text {
        position: absolute;
        width: 200px;
        background: #3a3a3a;
        height: 200px;
        border-radius: 200px;
        top: -75px;
        left: 50%;
        margin-left: -100px;
        text-align: center;

        span {
            display: inline-block;
            margin-top: 35px;
            font-size: 18px;
            font-family: "Bebas", sans-serif;
            color: #fff;
        }
    }
}
.quotes-actual-slider {
    padding-bottom: 20px;

    .owl-dots {

        @media screen and (max-width: $mobileWide) {
            display: none;
        }
    }
}
.quotes-slider {
    text-align: center;

    .item > p {
        width: 500px;
        margin: 10px auto;
        font-size: 16px;
        line-height: 24px;
        font-weight: 300;

        @media screen and (max-width: 520px) {
            width: 100%;
        }
    }
    blockquote {

        svg {
            width: 14px;
            // ie fix
            max-height: 13px;
            height: auto;
            position: relative;

            &:first-child {
                top: 10px;
                left: -7px;
            }
            &:last-child {
                top: -10px;
                left: 7px;
                transform: rotate(180deg);
                -webkit-transform: rotate(180deg);
                -moz-transform: rotate(180deg);
                -ms-transform: rotate(180deg);
            }
        }
    }
}
blockquote {

    p {
        font-size: 20px;
        line-height: 38px;
        font-weight: 300;
        font-style: italic;
        margin: 40px 100px;

        @media screen and (max-width: $tabletPortrait) {
            margin: 40px 20px;
        }
        @media screen and (max-width: $mobileWide) {
            line-height: 32px;
        }
        @media screen and (max-width: 500px) {
            margin: 20px 0 0;
        }
        @media screen and (max-width: $androidPortrait) {
            font-size: 16px;
            line-height: 26px;
        }
    }
}
.red-section {
    background: #d72525;
    padding: 80px 0 40px;

    h2 {
        text-align: center;
        color: #fff;
    }
    blockquote {

        p {
            text-align: center;
            color: #fff;
        }
        svg {
            width: 14px;
            // ie fix
            max-height: 13px;
            height: auto;
            display: inline-block;
            position: relative;
            top: -7px;

            &:first-child {
                left: -5px;
            }
            &:last-child {
                left: 5px;
                top: 7px;
                transform: rotate(180deg);
                -webkit-transform: rotate(180deg);
                -moz-transform: rotate(180deg);
                -ms-transform: rotate(180deg);
            }

            path {
                fill: #fff;
            }
        }
    }
}
.leading-brain-section {
    @include group;
    background: url('../img/leading-brain-section.jpg') no-repeat center top / cover;

    .center {
        height: 540px;
        position: relative;

        @media screen and (max-width: 950px) {
            height: auto;
        }
    }
}
.brain-left-side {
    width: 550px;
    position: absolute;
    top: 0;
    left: 0;

    @media screen and (max-width: 1000px) {
        width: 450px;
    }
    @media screen and (max-width: 850px) {
        width: 50%;
    }

    &.loaded {
        top: 100px;

        @media screen and (max-width: 950px) {
            top: 30px;
        }

        @media screen and (max-width: $mobileWide) {
            display: inline-block;
            width: 100%;
            margin-top: 50px;
            top: 0;
            left: 0;
            position: static;
        }
    }

    img {
        width: 550px;
        height: auto;
        position: absolute;
        top: 10px;
        left: 0;
    }
}
.brain-right-side {
    width: 50%;
    float: right;
    text-align: right;

    @media screen and (max-width: $mobileWide) {
        width: 100%;
        text-align: center;
    }

    h2 {
        color: #fff;
        max-width: 520px;
        margin-top: 40px;
        display: inline-block;

        @media screen and (max-width: $tabletPortrait) {
            font-size: 40px;
            line-height: 50px;
            width: auto;
        }
        @media screen and (max-width: $mobileWide) {
            margin-top: 0;
        }
    }
    p {
        color: #fff;
        max-width: 450px;
        float: right;

        @media screen and (max-width: $tabletPortrait) {
            font-size: 20px;
            line-height: 28px;
        }
        @media screen and (max-width: $mobileWide) {
            width: 100%;
            text-align: center;
        }
    }
}
.brain-buttons {
    clear: both;
    text-align: center;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;

    @media screen and (max-width: 950px) {
        position: static;
    }

    .button {
        width: 100%;
        margin: 10px 40px;
        max-width: 310px;
        font-size: 16px;

        @media screen and (max-width: 1200px) {
            margin: 10px 20px;
        }
        @media screen and (max-width: $mobileWide) {
            margin: 10px 0;
        }
    }
}
.counter-holder {
    @include group;
    padding: 40px 0px;

    .single-counter {
        float: left;
        width: 33%;
        box-sizing: border-box;
        padding: 0 40px;
        position: relative;
        text-align: center;

        @media screen and (max-width: $tabletWide) {
            padding: 0 30px;
        }
        @media screen and (max-width: $tabletPortrait) {
            width: 100%;
            margin-bottom: 70px;

            &:last-child {
                margin-bottom: 0;
            }
        }

        .circle {
            position: absolute;
            top: -50px;
            width: 200px;
            height: 200px;
            border-radius: 150px;
            background: rgba(249, 249, 249, 0.6);
            left: 50%;
            margin-left: -100px;
        }
        .count {
            font-size: 94px;
            text-align: center;
            font-family: $font-family-second;
            position: relative;
            color: #363636;
            z-index: 1;

            @media screen and (max-width: $tabletWide) {
                font-size: 70px;
            }
        }
        h2 {
            text-align: center;
            border-bottom: 3px solid #363636;
            position: relative;
            z-index: 1;
            font-size: 28px;
            line-height: 40px;
            display: inline-block;

            @media screen and (max-width: $tabletWide) {
                font-size: 26px;
                line-height: 32px;
            }
            @media screen and (max-width: $tabletPortrait) {
                margin-bottom: 30px;
            }
        }
    }
}
// flexslider end
.owl-carousel.owl-loaded.trust-us-slider {

    .owl-item {
        text-align: center;

        .item {
            display: flex;
            height: 130px;
            align-items: center;
        }
        img {
            width: auto;
            height: auto;
            max-width: 120px;
            max-height: 120px;
            display: block;
            margin: 0 auto;
        }
    }
}
.inner-top-header {
    padding: 70px 0;
    background: url('../img/header-bg@2x.png') no-repeat center top / cover;
    overflow: hidden;

    @media screen and (max-width: $mobileWide) {
        height: 68px;
        overflow: hidden;
        box-sizing: border-box;
        margin: 0;
        padding: 0;
        background: none;
    }

    .change-language-holder {
        top: -60px;

        @media screen and (max-width: $mobileWide) {
            top: 25px;
        }
    }
    .logo {

        @media screen and (max-width: $androidPortrait) {
            display: block;
        }

        @media screen and (max-width: $mobileWide) {
            display: none;
        }

        svg {

            @media screen and (max-width: $androidPortrait) {
                margin: 0 auto 30px;
                display: block;
            }
        }
    }
    .center {
        position: relative;

        @media screen and (max-width: $androidPortrait) {
            text-align: center;
        }
    }
    .action-text {
        z-index: 5;
        width: 110px;
        height: 110px;

        @media screen and (max-width: $tabletPortrait) {
            width: 80px;
            height: 80px;
            font-size: 22px;
            line-height: 26px;
        }
        @media screen and (max-width: $androidPortrait) {
            position: relative;
            display: inline-block;
            margin: 0 5px;
        }
        @media screen and (max-width: $mobileWide) {
            display: none;
        }

        &.red {
            top: -30px;
            left: 270px;
            bottom: auto;
            right: auto;

            @media screen and (max-width: $tabletPortrait) {
                top: -50px;
                left: 250px;
            }
            @media screen and (max-width: $androidPortrait) {
                top: 0;
                left: 0;
                margin-left: 0;
            }

            span {
                margin-left: -41px;

                @media screen and (max-width: $tabletPortrait) {
                    margin-left: -29px;
                }
            }
        }
        &.black {
            left: 650px;
            top: -40px;
            bottom: auto;
            right: auto;

            @media screen and (max-width: $tabletPortrait) {
                left: 220px;
                top: 55px;
            }
            @media screen and (max-width: $androidPortrait) {
                top: 0;
                left: 0;
            }

            span {

                @media screen and (max-width: $tabletPortrait) {
                    margin-left: -25px;
                }
            }
        }
        &.gray {
            top: 5px;
            left: 470px;
            bottom: auto;
            right: auto;

            @media screen and (max-width: $tabletPortrait) {
                left: 340px;
            }
            @media screen and (max-width: $androidPortrait) {
                top: 0;
                left: 0;
                margin-right: 0;
            }

            span {
                margin-left: -46px;

                @media screen and (max-width: $tabletPortrait) {
                    margin-left: -31px;
                }
            }
        }

        span {
            margin-top: -17px;
            margin-left: -37px;
        }
    }
    .top-header-animation {
        position: absolute;
        top: 0;
        right: 0;
        z-index: 2;

        @media screen and (max-width: $androidPortrait) {
            display: none;
        }

        .single-person-holder {
            position: absolute;
            top: 0;
            width: 210px;
            height: 200px;
            right: 63px;

            &.visible {

                img {
                    transform: translateY(0);
                    -webkit-transform: translateY(0);
                    -moz-transform: translateY(0);
                    -ms-transform: translateY(0);
                    opacity: 1;
                    visibility: visible;
                }
                .circle {
                    transition: all 0.8s ease 0.7s;
                    transform: translateY(0);
                    -webkit-transform: translateY(0);
                    -moz-transform: translateY(0);
                    -ms-transform: translateY(0);
                    opacity: 1;
                    visibility: visible;
                }
                .text-circle {
                    transition: all 0.8s ease 0.7s;
                    transform: translateY(0);
                    -webkit-transform: translateY(0);
                    -moz-transform: translateY(0);
                    -ms-transform: translateY(0);
                    opacity: 1;
                    visibility: visible;
                }
            }
            &.we-work.de {

                p {
                    left: 15px;
                }
            }
            &.we-love.de {

                p {
                    left: 60px;
                }
            }
            &.we-love, &.we-work {

                p {
                    left: 40px;
                }
            }

            .circle {
                background: #444;
                transition: all 0.8s;
                transform: translateY(200px);
                -webkit-transform: translateY(200px);
                -moz-transform: translateY(200px);
                -ms-transform: translateY(200px);
                opacity: 0;
                visibility: hidden;
            }
            .text-circle {
                transition: all 0.8s;
                transform: translateY(200px);
                -webkit-transform: translateY(200px);
                -moz-transform: translateY(200px);
                -ms-transform: translateY(200px);
                opacity: 0;
                visibility: hidden;
            }
            span {
                width: 100%;
            }
            p {
                font-size: 16px;
                line-height: 22px;
                bottom: 60px;
            }
        }
        img {
            width: auto;
            height: 360px;
            top: -40px;
            left: 140px;
            transition: all 0.8s;
            transform: translateY(200px);
            -webkit-transform: translateY(200px);
            -moz-transform: translateY(200px);
            -ms-transform: translateY(200px);
            opacity: 0;
            visibility: hidden;
        }
    }
}
.inner-page-background-section {
    overflow: hidden;
    background-repeat: no-repeat;
    background-position: center top;
    background-size: cover;

    &.contact-us {
        height: 350px;

        @media screen and (max-width: 1500px) {
            height: 350px;
        }
        @media screen and (max-width: 1200px) {
            padding: 0;
        }
    }

    .center {
        overflow: hidden;
        height: 340px;

        // @media screen and (max-width: 1500px) {
        //     height: 270px;
        // }
        @media screen and (max-width: $androidPortrait) {
            height: 260px;
        }
    }

    .bubble-holder {
        display: table;
        width: 250px;
        height: 250px;
        margin-top: 45px;

        @media screen and (max-width: 1500px) {
            margin-top: 10px;
        }
        @media screen and (max-width: $androidPortrait) {
            height: 220px;
            width: 220px;
            margin: 0 auto;
        }
    }
    .bubble {
        background: rgba(255, 255, 255, 0.6);
        border-radius: 100%;
        text-align: center;
        width: 100%;
        display: table-cell;
        height: 250px;
        vertical-align: middle;
        display: table;

        @media screen and (max-width: $androidPortrait) {
            height: 220px;
        }

        h2 {
            padding: 0 40px;
            line-height: 52px;
            font-size: 46px;
            display: table-cell;
            vertical-align: middle;

            @media screen and (max-width: 1500px) {
                line-height: 42px;
                font-size: 36px;
            }
        }
    }
}
/* The typing effect */
@keyframes typing {
  from { width: 0; }
  to { width: 100%; }
}

/* The typewriter cursor effect */
@keyframes blink-caret {
  from, to { border-color: transparent }
  50% { border-color: #ddd; }
}
@keyframes remove-maker {
  0% { width: 100%; }
  100% { border: 0; width: 100%; }
}

// add some animations
.translate-from-left {
    transition: all 2s;
    transform: translateX(-100%);
    -webkit-transform: translateX(-100%);
    -ms-transform: translateX(-100%);
    -moz-transform: translateX(-100%);
    opacity: 0;
    visibility: hidden;

    &.visible {
        transform: translateX(0);
        -webkit-transform: translateX(0);
        -ms-transform: translateX(0);
        -moz-transform: translateX(0);
        opacity: 1;
        visibility: visible;
    }
}
.translate-from-down {
    transition: all 2s;
    transform: translateY(-50px);
    -webkit-transform: translateY(-50px);
    -ms-transform: translateY(-50px);
    -moz-transform: translateY(-50px);
    opacity: 0;
    visibility: hidden;

    &.visible {
        transform: translateY(0);
        -webkit-transform: translateY(0);
        -ms-transform: translateY(0);
        -moz-transform: translateY(0);
        opacity: 1;
        visibility: visible;
    }
}
.translate-from-right {
    transition: all 2s;
    opacity: 0;
    visibility: hidden;
    transform: translateX(50px);
    -webkit-transform: translateX(50px);
    -ms-transform: translateX(50px);
    -moz-transform: translateX(50px);

    &.visible {
        opacity: 1;
        visibility: visible;
        transform: translateX(0);
        -webkit-transform: translateX(0);
        -ms-transform: translateX(0);
        -moz-transform: translateX(0);
    }
}
.translate-opacity {
    transition: all 2s;
    opacity: 0;
    visibility: hidden;

    &.visible {
        opacity: 1;
        visibility: visible;
    }
}
body .okayNav__menu-toggle {
    top: 21px;

    span {
        background: #fff;
    }
}
.okayNav__nav--invisible.nav-right {
    background: #323232;
    overflow-y: scroll;
    width: 100%;
    display: block;
    max-width: 240px;
    top: 68px;

    li {
        display: block;
        width: 100%;
    }
    a {
        display: block;
        width: 100%;
        background: #323232;
    }
}
.okayNav__nav--invisible.nav-right a {
    box-sizing: border-box;
}
.contact-info {

    h3 {
        color: #cb0e1d;
        font-size: 20px;
        line-height: 24px;
        margin-bottom: 10px;
    }
    hr {
        margin: 20px 0;
        border: 0;
        border-top: 1px solid #ddd;
    }
    p {
        font-size: 16px;
        line-height: 28px;
        margin: 5px 0;

        a {
            color: #3a3a3a;
        }
    }
}
.map-info {

    p {
        font-size: 16px;
        max-width: 210px;
        line-height: 22px;
        text-align: center;
        margin: 5px 0;
        font-weight: 300;
    }
}
.single-blog-post {
    float: left;
    margin: 0 20px 40px;
    width: 29%;

    @media screen and (max-width: 1100px) {
        width: 45%;
        float: none;
        display: inline-block;
        vertical-align: top;
    }
    @media screen and (max-width: 950px) {
        width: 40%;
    }
    @media screen and (max-width: $tabletPortrait) {
        width: 100%;
        max-width: 500px;
        margin: 0 0 40px;
    }

    .blog-post-image {
        background-size: cover;
        background-position: center center;
        background-repeat: no-repeat;
        width: 100%;
        height: 220px;
        box-shadow: 0px 4px 30px 2px rgba(0, 0, 0, 0.2);
        margin-bottom: 20px;

        @media screen and (max-width: 950px) {
            height: 200px;
        }
    }
    .single-blog-post-data {
        text-align: center;
    }
    .date {
        font-weight: 300;
        color: #323232;
        text-align: center;
    }
    h3 {
        margin: 10px 0;
        font-size: 24px;
        line-height: 32px;
        font-weight: 600;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
    }
    p {
        line-height: 28px;
        margin: 10px 0;
    }
    a {
        color: #d72525;
        font-weight: 500;
    }
}
.blog-post-holder {
    text-align: center;
}
.members-holder {
    text-align: center;
}
.single-member {
    width: 20%;
    margin: 10px 22px 20px;
    display: inline-block;
    vertical-align: top;
    position: relative;

    &.executive-member {
        width: 20%;

        @media screen and (max-width: 1150px) {
            width: 30%;
        }
        @media screen and (max-width: 900px) {
            width: 40%;
        }
        @media screen and (max-width: $mobileWide) {
            width: 100%;
        }
    }

    @media screen and (max-width: 1150px) {
        width: 30%;
        margin: 10px 15px 60px;
    }
    @media screen and (max-width: 1080px) {
        margin: 10px 10px 60px;
    }
    @media screen and (max-width: 900px) {
        width: 40%;
    }
    @media screen and (max-width: $mobileWide) {
        width: 100%;
        margin: 0 0 60px;
    }

    &:hover {

        .role {
            opacity: 1;
            visibility: visible;
            transform: translateX(0) rotate(0deg);
            -webkit-transform: translateX(0) rotate(0deg);
            -moz-transform: translateX(0) rotate(0deg);
            -ms-transform: translateX(0) rotate(0deg);
        }
        .arrow {
            opacity: 0.6;
            visibility: visible;
        }
    }

    h3 {
        font-size: 22px;
        font-weight: 400;
        line-height: 30px;
        text-align: center;
    }
    .arrow {
        background: #eee;
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        transition: all 0.4s;
        opacity: 0;
        visibility: hidden;
        border-radius: 100%;
    }
    .role {
        background: #323232;
        padding: 15px;
        color: #fff;
        font-size: 14px;
        line-height: 18px;
        font-weight: 400;
        position: absolute;
        top: -50px;
        right: -50px;
        width: 85px;
        height: 85px;
        text-align: center;
        border-radius: 100%;
        display: table;
        transition: all 0.8s;
        opacity: 0;
        z-index: 10;
        visibility: hidden;
        transform: translateX(20px) rotate(20deg);
        -webkit-transform: translateX(20px) rotate(20deg);
        -moz-transform: translateX(20px) rotate(20deg);
        -ms-transform: translateX(20px) rotate(20deg);

        span {
            vertical-align: middle;
            display: table-cell;
        }
    }
    .image-holder {
        position: relative;
        width: 150px;
        margin: 0 auto 10px;

        .member-pic {
            width: 100%;
            height: 150px;
            overflow: hidden;
            border-radius: 100%;
            background-position: center center;
            background-size: cover;
            background-repeat: no-repeat;
        }
        img {
            width: 100%;
            height: auto;
            border-radius: 100%;
            display: block;
            box-shadow: 0px 4px 30px 2px rgba(0, 0, 0, 0.2);
        }
    }
}
.member-buttons {
    text-align: center;

    .button {
        width: 100%;
        min-width: 0;
        max-width: 250px;
        margin: 10px;
    }
}
.clients {
    text-align: center;

    h2 {
        padding: 0 20px 40px;
        text-align: center;

        @media screen and (max-width: $mobileWide) {
            padding-bottom: 20px;
        }
    }
    .clients-button-holder {

        @media screen and (max-width: 500px) {
            margin-top: 20px;
        }
    }
}
.clients-column {
    width: 30%;
    margin: 0 15px 20px;
    display: inline-block;
    vertical-align: top;

    @media screen and (max-width: 1050px) {
        width: 27%;
        margin: 0 10px 20px;
    }
    @media screen and (max-width: 500px) {
        width: 100%;
        margin: 0;
    }

    ul {
        margin: 0;
        padding: 0;
        list-style: none;

        li {
            display: block;
            text-align: center;
            color: #3a3a3a;
            font-size: 22px;
            line-height: 28px;

            @media screen and (max-width: $tabletPortrait) {
                font-size: 18px;
                line-height: 24px;
            }
            @media screen and (max-width: $androidPortrait) {
                font-size: 16px;
                line-height: 22px;
            }
        }
    }
}
.single-post-background {

    &.member-post-background {

        @media screen and (max-width: $tabletPortrait) {
            overflow-x: scroll;
        }

        .inner-page-background-section {
            width: 1110px;
            margin: 0 auto;

            @media screen and (max-width: 1130px) {
                width: 100%;
            }
            @media screen and (max-width: $tabletPortrait) {
                width: 1110px;
            }
        }
    }

    .inner-page-background-section {
        background-position: center center;

        @media screen and (max-width: $tabletPortrait) {
            background-position: center left;
        }
    }
}
.home-anchor {
    position: absolute;
    top: -88px;
    left: 0;
    width: 100px;
    height: 100px;
    opacity: 0;
    visibility: hidden;

    &#love {
        top: -120px;
    }
}
.value-column-holder {
    @include group;

    blockquote {
        margin: 0;
        float: left;
        width: 50%;
        padding-right: 40px;
        box-sizing: border-box;

        &:last-child {
            padding-right: 0;
        }

        p {
            margin: 10px 0;
        }
    }
}
.value-column {
    width: 50%;
    padding: 0 15px;
    box-sizing: border-box;
    float: left;

    &:first-child {
        padding-left: 0;
    }
    &:last-child {
        padding-right: 0;
    }

    @media screen and (max-width: $mobileWide) {
        width: 100%;
        padding: 0;
    }

    p {
        margin: 0 0 30px;
    }
}
.g-recaptcha {
    margin-top: 30px;
}
.single-member-page-body {

    h1 {
        margin-bottom: 20px;
    }
    h3 {
        font-family: Roboto,sans-serif;
        text-transform: inherit;
        font-size: 26px;
        line-height: 42px;
    }
}
.facts-table {
    width: 100%;

    .heading-td {
        width: 25%;
        color: #d72525;
        font-size: 34px;
        line-height: 42px;
        text-align: right;
        font-weight: 600;
        padding: 30px 0;
        text-transform: uppercase;
        font-family: $font-family-second;

        @media screen and (max-width: 500px) {
            display: block;
            width: 100%;
            text-align: center;
            padding: 10px 0;
            font-size: 26px;
            line-height: 34px;
        }
    }
    .normal-td {
        width: 75%;
        padding: 20px 30px;
        text-align: left;
        color: #323232;
        font-size: 18px;
        line-height: 32px;
        font-weight: 300;

        @media screen and (max-width: 500px) {
            display: block;
            width: 100%;
            text-align: center;
            padding: 10px 0;
            font-size: 18px;
            line-height: 20px;
        }
    }
}
.single-circle-main-holder {
    float: left;
    margin: 120px 40px 20px;
    width: 280px;

    &.no-margin-top {
        margin-top: 0;
    }
    &.first-child {
        margin-left: 0;
    }
    &.last-child {
        margin-right: 0;
    }

    @media screen and (max-width: 1250px) {
        float: none;
        display: inline-block;
        vertical-align: top;
        margin: 120px 40px 20px;
    }
    @media screen and (max-width: 1100px) {
        margin: 120px 20px 20px;
    }
    @media screen and (max-width: 1050px) {
        margin: 20px 30px;

        &.no-margin-top {
            margin-top: 30px;
        }
        &:first-child {
            margin-left: 20px;
        }
        &:last-child {
            margin-right: 20px;
        }
    }
    @media screen and (max-width: $androidPortrait) {
        margin: 20px 0;
        width: 250px;

        &.no-margin-top {
            margin-top: 20px;
        }
        &:first-child {
            margin-left: 0;
        }
        &:last-child {
            margin-right: 0;
        }
    }

    > p {
        color: #363636;
        font-size: 21px;
        line-height: 31px;
        width: 100%;
        text-align: center;
        display: block;
        margin-top: 20px;
        font-weight: 600;
    }
}
.quality-circles-holder {
    margin: 40px 0;

    @media screen and (max-width: 1250px) {
        text-align: center;
    }
}
.single-circle-holder {
    border: 10px solid #d72525;
    box-sizing: border-box;
    display: table;
    width: 100%;
    border-radius: 100%;
    height: 280px;
    text-align: center;
    padding: 0 20px;
    box-shadow: 0px 4px 30px 2px rgba(0, 0, 0, 0.2);

    @media screen and (max-width: $androidPortrait) {
        height: 250px;
    }

    .single-circle-stats {
        display: table-cell;
        vertical-align: middle;
        width: 100%;
        height: 100%;

        h2 {
            color: #363636;
            font-family: 'Alfa Slab One', cursive;
            font-size: 56px;
            line-height: 62px;
            font-weight: 300;
        }
        p {
            font-size: 12px;
            line-height: 17px;
            margin: 0;
        }
    }
}
section.we-help-you-section {
    width: 100%;
}
.single-help-you {
    margin: 20px 0;

    &:last-child {
        margin-bottom: 0;
    }

    @media screen and (max-width: $mobileWide) {
        text-align: center;
    }

    .help-you-circle, p {
        display: inline-block;
        vertical-align: middle;
    }
    .help-you-circle {
        margin-right: 50px;
        background-color: #ddd;
        border-radius: 100%;
        width: 120px;
        height: 120px;

        @media screen and (max-width: $mobileWide) {
            margin-right: 0;
        }
    }
    img {
        width: 60px;
        height: auto;
        display: block;
        margin: 28px auto 0;

        @media screen and (max-width: $androidPortrait) {
            margin-top: 35px;
        }
    }
    p {
        font-size: 20px;
        line-height: 32px;
        max-width: 420px;
        color: #454545;

        @media screen and (max-width: 1000px) {
            max-width: 300px;
        }
        @media screen and (max-width: $androidPortrait) {
            font-size: 16px;
            line-height: 26px;
        }
    }
}
.hpti-section {
    background: url('../img/hpti-bg.jpg') no-repeat top left / cover;
    min-height: 300px;
    display: table;
    box-sizing: border-box;
    width: 100%;

    @media screen and (max-width: $tabletPortrait) {
        background-position: top right;
    }

    .center {
        display: table-cell;
        width: 100%;
        height: 100%;
        text-align: center;
        vertical-align: middle;
        box-sizing: border-box;
        padding: 0;

        .hpti-content-holder {
            margin: 0 auto;
            max-width: 550px;
            padding-left: 220px;

            @media screen and (max-width: $tabletWide) {
                padding-left: 0;
            }
        }
        h2 {
            color: #d72525;
        }
        p {
            color: #454545;
            margin: 20px 0 30px;
        }
    }
}
.video-holder {
    padding: 40px 0 0;
}
.video {
    background: url('../img/mac.png') no-repeat center center;
    width: 478px;
    height: 275px;
    position: relative;
    display: inline-block;
    vertical-align: middle;

    @media screen and (max-width: $tabletWide) {
        display: block;
        margin: 0 auto;
    }
    @media screen and (max-width: 600px) {
        background: none;
        width: 100%;
    }
    @media screen and (max-width: $androidPortrait) {
        height: 230px;
    }

    iframe {
        position: absolute;
        top: 16px;
        left: 57px;

        @media screen and (max-width: 600px) {
            width: 100%;
            top: 0;
            left: 0;
        }
    }
    .video-player-background {
        background: url('../img/play-second.png') no-repeat center center;
        width: 377px;
        height: 252px;
        position: absolute;
        top: 6px;
        cursor: pointer;
        left: 52px;
        z-index: 5;

        @media screen and (max-width: $mobileWide) {
            width: 100%;
            left: 0;
            top: 0;
            height: 280px;
        }
        @media screen and (max-width: $androidPortrait) {
            height: 230px;
        }
    }
}
.video-options {

    ul {
        display: inline-block;
        vertical-align: middle;

        @media screen and (max-width: $tabletWide) {
            width: 500px;
            display: block;
            padding-top: 40px;
            margin: 0 auto;
        }
        @media screen and (max-width: $mobileWide) {
            width: 300px;
            padding: 40px 0 0;
        }
        @media screen and (max-width: $androidPortrait) {
            width: 280px;
            padding: 40px 20px 0;
            box-sizing: border-box;
        }

        li a.active {
            color: #d72525;
        }
    }
}
