// ../Fonts
@import url('https://fonts.googleapis.com/css?family=Roboto:100,200,300,400,500,600,700');
@import url('https://fonts.googleapis.com/css?family=Alfa+Slab+One');

@font-face {
    font-family: 'Bebas Neue Bold';
    src: url('../fonts/BebasNeue Bold.eot');
    src: local('☺'), url('../fonts/BebasNeue Bold.woff') format('woff'), url('../fonts/BebasNeue Bold.ttf') format('truetype'), url('../fonts/BebasNeue Bold.svg') format('svg');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'Bebas Neue Book';
    src: url('../fonts/BebasNeue Book.eot');
    src: local('☺'), url('../fonts/BebasNeue Book.woff') format('woff'), url('../fonts/BebasNeue Book.ttf') format('truetype'), url('../fonts/BebasNeue Book.svg') format('svg');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'Bebas Neue Light';
    src: url('../fonts/BebasNeue Light.eot');
    src: local('☺'), url('../fonts/BebasNeue Light.woff') format('woff'), url('../fonts/BebasNeue Light.ttf') format('truetype'), url('../fonts/BebasNeue Light.svg') format('svg');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'Bebas Neue Regular';
    src: url('../fonts/BebasNeue Regular.eot');
    src: local('☺'), url('../fonts/BebasNeue Regular.woff') format('woff'), url('../fonts/BebasNeue Regular.ttf') format('truetype'), url('../fonts/BebasNeue Regular.svg') format('svg');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'Bebas Neue Thin';
    src: url('../fonts/BebasNeue Thin.eot');
    src: local('☺'), url('../fonts/BebasNeue Thin.woff') format('woff'), url('../fonts/BebasNeue Thin.ttf') format('truetype'), url('../fonts/BebasNeue Thin.svg') format('svg');
    font-weight: normal;
    font-style: normal;
}